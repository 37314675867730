/* eslint-disable max-lines -- complex method */
import {
  PhaseColor,
  IUserPhase,
  PhaseMastery,
  IPhase,
  Sport,
} from '@hulanbv/toftennis';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';
import { usePhases } from '../../domain/phases/hooks/use-phases.hook';
import { usePublishes } from '../../domain/publishes/hooks/use-publishes.hook';
import { publishService } from '../../domain/publishes/publish.service';
import { useUserPhasesByColor } from '../../domain/user-phases/hooks/use-user-phases-by-color.hook';
import { userPhaseService } from '../../domain/user-phases/user-phase.service';
import { AsyncFlexElement } from '../elements/async-flex-element/async-flex-element.component';
import { ButtonIconElement } from '../elements/button-icon-element/button-icon-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { PhaseColorSelector } from '../elements/phase-color-selector-element/phase-color-selector-element.component';
import { SwirlElement } from '../elements/swirl-element/swirl-element.component';
import { ConfirmModalTemplate } from './confirm-modal-template.component';
import { InfoModalTemplate } from './info-modal-template.component';
import { UserPhasesListTemplate } from './user-phases-list-template.component';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { Clique } from '../../domain/common/types/clique.type';
import { ReactComponent as PublishIcon } from '../../assets/graphics/toftennis-publish.svg';
import { sportIcons } from '../../domain/common/constants/sport-icons.constant';
import { useSettingsContext } from '../../domain/settings/use-settings-context.hook';

type Props = {
  selectedClique: Clique;
  workingUserIds: string[];
  view: 'swirl' | 'list';
};

const PhaseLevelsTemplate: FC<Props> = ({
  workingUserIds,
  selectedClique,
  view,
}) => {
  const { sessionToken } = useAuthContext();
  const { activeSports } = useSettingsContext();

  const { add: addNotification } = useNotificationContext();
  const { openModal } = useModalContext();
  const [selectedColor, setSelectedColor] = useState<PhaseColor | null>(null);
  const [selectedSport, setSelectedSport] = useState<Sport>(
    activeSports[0] ?? Sport.TENNIS,
  );
  const [lastSynchronisation, setLastSynchronisation] = useState<Date | null>(
    null,
  );
  const [rawUserPhases, setRawUserPhases] = useState<IUserPhase[]>([]);

  const { publishes, refresh: fetchPublishes } = usePublishes({
    match: {
      instructorId: sessionToken?.userId,
    },
    limit: 1,
    sort: ['-createdAt'],
  });

  const { userPhases: fetchedUserPhases, refresh: fetchUserPhases } =
    useUserPhasesByColor(
      selectedSport,
      selectedColor ?? PhaseColor.RED,
      {
        match: {
          userId: { $in: workingUserIds },
          $or:
            lastSynchronisation !== null
              ? [
                  { createdAt: { $gte: lastSynchronisation.toISOString() } },
                  { updatedAt: { $gte: lastSynchronisation.toISOString() } },
                ]
              : [{}],
        },
        sort: ['-createdAt'],
        distinct: ['userId', 'phaseLevelId'],
      },
      true,
    );

  /**
   * Select the first group by default when the groups are fetched
   */
  useEffect(() => {
    const group = selectedClique.group ?? null;
    if (group !== null) {
      setSelectedColor(group?.color ?? PhaseColor.RED);
    } else if (selectedColor === null) {
      setSelectedColor(PhaseColor.RED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't trigger on changing selectedClique
  }, [selectedClique.group]);

  useEffect(() => {
    setLastSynchronisation(null);
    setRawUserPhases([]);
  }, [selectedColor, workingUserIds]);

  useEffect(() => {
    if (
      lastSynchronisation === null &&
      rawUserPhases.length === 0 &&
      selectedColor !== null &&
      workingUserIds.length > 0
    ) {
      fetchUserPhases();
    }
  }, [
    fetchUserPhases,
    lastSynchronisation,
    rawUserPhases,
    selectedColor,
    workingUserIds,
  ]);

  useEffect(() => {
    const fetchedUserPhasesMap =
      fetchedUserPhases?.reduce<Record<string, Record<string, IUserPhase>>>(
        (prev, curr) => {
          // eslint-disable-next-line no-param-reassign -- we need to assign a key
          prev[curr.userId] = prev[curr.userId] ?? {};
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-param-reassign  -- we know the key exists
          prev[curr.userId]![curr.phaseLevelId] = curr;
          return prev;
        },
        {},
      ) ?? {};
    const updatedPhaseIds: string[] = [];
    const updatedUserPhases = rawUserPhases.map((userPhase) => {
      const updatedPhase =
        fetchedUserPhasesMap[userPhase.userId]?.[userPhase.phaseLevelId];
      if (updatedPhase) {
        updatedPhaseIds.push(updatedPhase.id ?? '');
        return updatedPhase;
      }
      return userPhase;
    });
    updatedUserPhases.push(
      ...(fetchedUserPhases?.filter(
        ({ id }) => !updatedPhaseIds.includes(id ?? ''),
      ) ?? []),
    );

    setLastSynchronisation(new Date());
    setRawUserPhases(updatedUserPhases);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't trigger on changing rawUserPhases
  }, [fetchedUserPhases]);

  const userPhases = useMemo(
    () =>
      rawUserPhases.filter(({ mastery }) => mastery !== PhaseMastery.UNTREATED),
    [rawUserPhases],
  );

  const { phases } = usePhases({
    match: {
      sport: { $in: activeSports },
    },
    sort: ['phaseIndex'],
    populate: [{ path: 'phaseLevels', sort: ['level'] }],
  });
  const currentColorPhases = useMemo(
    () =>
      (phases ?? []).filter(
        (phase) =>
          phase.color === selectedColor && phase.sport === selectedSport,
      ),
    [phases, selectedColor, selectedSport],
  );

  const handlePublishClick = useCallback(async () => {
    if (!selectedClique) {
      return;
    }

    if (
      (publishes?.[0]?.createdAt ?? new Date()) >
      (rawUserPhases?.[0]?.createdAt ?? new Date())
    ) {
      openModal(() => (
        <InfoModalTemplate
          title={'Geen nieuwe gegevens!'}
          description={`Er zijn geen aanpassingen gedaan in het bijhouden van ontwikkeling sinds d.d. ${new Date(
            publishes?.[0]?.createdAt ?? '',
          )?.toLocaleDateString()}. Op dit moment kunnen er geen nieuwe gegevens gepubliceerd worden.`}
        />
      ));
      return;
    }

    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          cancelButtonText="Annuleren"
          confirmButtonText="Publiceren"
          content={
            <p>
              Weet je zeker dat de ontwikkeling van al je leerlingen up-to-date
              is? Door op de knop te drukken worden gegevens gepubliceerd. Het
              maakt deze gegevens direct beschikbaar voor gedetailleerd advies.
            </p>
          }
        />
      ));
      if (!isConfirmed) {
        return;
      }

      // publish the data
      await publishService.post({
        instructorId: sessionToken?.userId ?? '',
        clubId:
          selectedClique.type === 'club'
            ? selectedClique.id ?? ''
            : selectedClique.clubId ?? '',
      });

      // refetch publishes to update the publish button
      fetchPublishes();

      openModal(() => (
        <InfoModalTemplate
          title={'Gepubliceerd!'}
          description="Wat tof dat je meewerkt aan de toekomst van Tenniskids. Deze gegevens leveren een belangrijke bijdrage aan het monitoren en verbeteren van het programma."
        />
      ));
    } catch {
      addNotification(
        'Er is iets misgegaan bij het publiceren van de data',
        'error',
      );
    }
  }, [
    addNotification,
    fetchPublishes,
    openModal,
    publishes,
    rawUserPhases,
    selectedClique,
    sessionToken?.userId,
  ]);

  /**
   * An array with the highest acquired levels for each phase
   */
  const achievedPhaseLevels: [level: number, mastery: PhaseMastery][] =
    useMemo(() => {
      const masteriesByLevelId = (userPhases ?? []).reduce<
        Record<string, PhaseMastery[]>
      >((prev, curr) => {
        // eslint-disable-next-line no-param-reassign -- we need to assign a key
        prev[curr.phaseLevelId] = (prev[curr.phaseLevelId] ?? []).concat(
          curr.mastery,
        );
        return prev;
      }, {});

      return currentColorPhases.map((phase) => {
        for (const phaseLevel of [...(phase.phaseLevels ?? [])].reverse()) {
          const levelMasteries = masteriesByLevelId[phaseLevel.id ?? ''] ?? [];
          if (
            levelMasteries.length > 0 &&
            levelMasteries.length === workingUserIds.length
          ) {
            return [phaseLevel.level, Math.min(...levelMasteries)];
          }
        }
        return [0, PhaseMastery.TREATED];
      });
    }, [userPhases, currentColorPhases, workingUserIds.length]);

  // save the level change for all selected users
  const handleLevelChange = useCallback(
    async (newLevel: number, newMastery: PhaseMastery, phase: IPhase) => {
      try {
        if (!workingUserIds.length || !phase?.phaseLevels) {
          return;
        }
        const phaseLevelIds = (phase.phaseLevels ?? []).map(
          ({ id }) => id ?? '',
        );

        // delete all levels higher than the achieved level
        const unlearnedUserPhases = (userPhases ?? []).filter(
          ({ phaseLevelId }) =>
            phaseLevelIds.indexOf(phaseLevelId) + 1 > newLevel,
        );
        await Promise.all(
          unlearnedUserPhases.map((unlearnedPhase) =>
            userPhaseService.post({
              mastery: PhaseMastery.UNTREATED,
              phaseLevelId: unlearnedPhase.phaseLevelId,
              userId: unlearnedPhase.userId,
              instructorId: sessionToken?.userId,
              groupId:
                selectedClique?.type === 'group'
                  ? selectedClique?.id
                  : undefined,
            }),
          ),
        );

        // create a list of achieved userPhases
        const learnedPhaseLevels = phase.phaseLevels?.filter(
          ({ id }) => phaseLevelIds.indexOf(id ?? '') + 1 <= newLevel,
        );
        let learnedUserPhases = (learnedPhaseLevels ?? []).reduce<IUserPhase[]>(
          (prev, curr) =>
            prev.concat(
              workingUserIds.map((userId) => ({
                userId,
                phaseLevelId: curr.id ?? '',
                mastery:
                  curr.level < newLevel ? PhaseMastery.MASTERED : newMastery,
                instructorId: sessionToken?.userId,
                groupId:
                  selectedClique?.type === 'group'
                    ? selectedClique?.id
                    : undefined,
              })),
            ),
          [],
        );

        // filter out the userPhases that are already unchanged in the database
        learnedUserPhases = learnedUserPhases.filter(
          ({ userId, phaseLevelId }) =>
            !rawUserPhases?.some(
              (userPhase) =>
                userPhase.userId === userId &&
                userPhase.phaseLevelId === phaseLevelId &&
                userPhase.mastery === newMastery,
            ),
        );

        if (learnedUserPhases.length > 0) {
          await userPhaseService.postMany({
            payload: learnedUserPhases,
          });
        }

        fetchUserPhases();
      } catch {
        addNotification(
          'Er is iets misgegaan bij het updaten van de level progressie',
          'error',
        );
      }
    },
    [
      workingUserIds,
      userPhases,
      fetchUserPhases,
      sessionToken?.userId,
      selectedClique?.type,
      selectedClique?.id,
      rawUserPhases,
      addNotification,
    ],
  );

  // Effect invoked when the shouldShowListView state changes.
  useEffect(() => {
    // When the list view is not shown, the Swirl will be rendered instead.
    // Since the Swirl makes use of touch events, we need to disable the
    // body's overflow property to prevent the page from being dragged rather
    // than the Swirl.
    if (view === 'swirl') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // The auto scroll overflow state will be restored during the cleanup.
    return () => {
      document.body.style.overflow = 'auto';
    };
    // BUG -- If a modal would open, the overflow state will be overwritten!
  }, [view]);

  return (
    <>
      {selectedColor !== null && (
        <AsyncFlexElement
          flexProps={{
            flex: 1,
          }}
        >
          <AsyncFlexElement
            isLoading={userPhases === null}
            flexProps={{
              flex: 1,
              alignItems: 'stretch',
              justifyContent: 'stretch',
            }}
          >
            <FlexElement
              justifyContent="space-between"
              direction="row"
              outerContentPadding
            >
              <FlexElement direction="row" justifyContent="start">
                {activeSports.map((sport) => {
                  const Icon = sportIcons[sport];
                  return (
                    <Icon
                      key={sport}
                      height={'1.2em'}
                      color={
                        selectedSport === sport
                          ? 'var(--brand-knltb-blue)'
                          : 'var(--brand-text-secondary)'
                      }
                      onClick={() => setSelectedSport(sport)}
                    />
                  );
                })}
              </FlexElement>
              <PhaseColorSelector
                key={selectedColor}
                selectedSport={selectedSport}
                onChange={setSelectedColor}
                defaultValue={selectedColor}
              />
            </FlexElement>
            {view === 'swirl' && (
              <>
                <SwirlElement
                  sport={selectedSport}
                  color={selectedColor}
                  achievedPhaseLevels={achievedPhaseLevels}
                  phases={currentColorPhases}
                  onLevelChange={handleLevelChange}
                  attributes={{
                    style: {
                      flex: 1,
                      position: 'relative',
                    },
                  }}
                />
                {selectedClique && selectedClique.club?.hasPublishModule && (
                  <ButtonIconElement
                    flavour="dark-blue"
                    fitContent
                    icon={PublishIcon}
                    attributes={{
                      onClick: handlePublishClick,
                    }}
                    isDisabled={
                      (publishes?.[0]?.createdAt ?? '') >
                      (rawUserPhases?.[0]?.createdAt ?? '')
                    }
                  >
                    Publiceren
                  </ButtonIconElement>
                )}
              </>
            )}
            {view === 'list' && (
              <UserPhasesListTemplate
                color={selectedColor}
                phases={currentColorPhases}
                achievedPhaseLevels={achievedPhaseLevels}
                onLevelChange={handleLevelChange}
              />
            )}
          </AsyncFlexElement>
        </AsyncFlexElement>
      )}
    </>
  );
};

export { PhaseLevelsTemplate };
/* eslint-enable max-lines -- complex method */
